import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import jsonToFormData from '@/libs/jsonToFormData'

const endpoint = () => 'orders/rental_sale'
const endpointCustomer = () => 'customers'
const Actions = crudActions(endpoint)

const payWithCard = (ctx, payload) => {
  const { order_id } = payload
  return axios.post(`${endpoint()}/${order_id}/pay-with-card`, payload)
}
const payWithToken = (ctx, payload) => {
  const { order_id } = payload
  return axios.post(`${endpoint()}/${order_id}/pay-with-token`, payload)
}
const payWithAch = (ctx, payload) => {
  const { order_id } = payload
  return axios.post(`${endpoint()}/${order_id}/pay-with-ach`, payload)
}

const getCardList = (ctx, id) => axios.get(`${endpointCustomer()}/${id}/cards`)

const cardKnoxGetToken = (ctx, payload) => axios.post('https://cdn.cardknox.com/api/ifields/gettoken', payload)
const payViaTerminal = (ctx, payload) => axios.post(`${payload.endpoint}`, jsonToFormData(payload.data), {
  headers: {
    'Content-Type': 'alllication/x-www-form-urlencode',
  },
})
const cancelPayViaTerminal = (ctx, payload) => axios.post(`${payload.endpoint}`, jsonToFormData(payload.data), {
  headers: {
    'Content-Type': 'alllication/x-www-form-urlencode',
  },
})
const approvedPayment = (ctx, { order_id }) => axios.post(`${endpoint()}/${order_id}/pay-point-of-sale`)

export default {
  ...Actions,
  payWithCard,
  payWithToken,
  payWithAch,
  getCardList,
  cardKnoxGetToken,
  payViaTerminal,
  cancelPayViaTerminal,
  approvedPayment,
}
