<template>
  <div>
    <quote-information />

    <div class="d-flex mt-1 justify-content-between">
      <event-information class=" col-6" />
      <quote-child-information class=" col-6" />
    </div>

    <div class="d-flex mt-1 justify-content-between">
      <quote-dispatch class=" col-6" />
      <quote-return class=" col-6 " />
    </div>
    <div
      class="form-wrapper mt-1"
    >
      <rental-items />
      <sale-items class="mt-1" />
      <other-charges-items class="mt-1" />
    </div>
    <totals
      class="mt-1"
      without-days
    />
    <internal-information class="mt-1" />

    <payment
      ref="payment"
      class="mt-1"
    />
    <quote-footer-buttons :form-ref="formRef" />
  </div>
</template>
<script>
import QuoteInformation from '@/views/main/orders/view/rental-sales/components/hold/QuoteInformation.vue'
import QuoteChildInformation from '@/views/main/orders/view/rental-sales/components/hold/QuoteChildInformation.vue'
import QuoteDispatch from '@/views/main/orders/view/rental-sales/components/hold/QuoteDispatch.vue'
import RentalItems from '@/views/main/orders/view/rental-sales/components/hold/RentalItems.vue'
import QuoteFooterButtons from '@/views/main/orders/view/rental-sales/components/approve/QuoteFooterButtons.vue'
import SaleItems from '@/views/main/orders/view/rental-sales/components/hold/SaleItems.vue'
import OtherChargesItems from '@/views/main/orders/view/rental-sales/components/hold/OtherChargesItems.vue'
import EventInformation from '@/views/main/orders/view/rental-sales/components/hold/EventInformation.vue'
import QuoteReturn from '@/views/main/orders/view/rental-sales/components/hold/QuoteReturn.vue'
import InternalInformation from '@/views/main/orders/view/rental-sales/components/hold/InternalInformation.vue'
import Totals from '@/views/main/orders/view/rental-sales/components/create/components/Totals.vue'
import Payment from '@/views/main/payment/Payment.vue'

export default {
  name: 'Approve',
  components: {
    Payment,
    Totals,
    InternalInformation,
    QuoteReturn,
    EventInformation,
    OtherChargesItems,
    SaleItems,
    QuoteFooterButtons,
    RentalItems,
    QuoteDispatch,
    QuoteChildInformation,
    QuoteInformation,
  },
  data() {
    return {
      formRef: null,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.formRef = this.$refs.payment
    })
  },
}
</script>
<style scoped lang="scss">

</style>
