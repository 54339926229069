<template>
  <div
    class="form-wrapper bg-transparent"
  >
    <div class="d-flex justify-content-between">
      <div
        class="form-wrapper"
        style="width: 256px;"
      >
        <h5 class="font-weight-bolder ">
          {{ 'Payment Options' }}
        </h5>
        <component
          :is="paymentGatewayFields[field].type"
          v-for="field in ['payment_gateway']"
          :key="field"
          v-model="paymentForm[field]"
          class="mt-1 mb-1"
          v-bind="getProps(field)"
        />
        <b-form-group
          v-slot="{ ariaDescribedby }"
          class="mt-1"
        >
          <b-form-radio
            v-for="radio in options"
            :key="radio.value"
            v-model="selected"
            class="d-flex mb-1"
            :aria-describedby="ariaDescribedby"
            :name="radio.value"
            :value="radio.value"
          >
            {{ $t(radio.text) }}
          </b-form-radio>
        </b-form-group>

      </div>

      <div
        class="form-wrapper flex-grow-1 ml-3"
      >
        <credit-card-on-file
          v-if="selected === 'CreditCardOnFile'"
          ref="credit-card-on-file"
        />
        <pay-with-credit-card v-if="selected === 'PayWithCreditCard'" />
        <pay-with-a-c-h v-if="selected === 'PayWithACH'" />
        <point-of-sale-e-m-v v-if="selected === 'PointOfSaleEmv'" />
      </div>
    </div>
    <charge-succeded-model
      ref="charge-succeded-model"
      @ok="okSuccededModel()"
    />
    <displayed-error-message-from-bbpos-system-model
      ref="displayed-error-message-from-bbpos-system-model"
      @errorCancel="cancelErrorMessage()"
      @tryAgain="tryAgainPayment()"
    />
    <insert-credit-carton-terminal-model
      ref="insert-credit-carton-terminal-model"
      @paymentCancel="cancelPayment()"
    />
    <!--    <button @click="qwe()">-->
    <!--      EWRTYUIO-->
    <!--    </button>-->
  </div>
</template>

<script>
import {
  BCol, BForm, BFormGroup, BFormRadio, BFormRadioGroup, BRow,
} from 'bootstrap-vue'
import CreditCardOnFile from '@/views/main/payment/components/CreditCardOnFile.vue'
import PayWithCreditCard from '@/views/main/payment/components/PayWithCreditCard.vue'
import PayWithACH from '@/views/main/payment/components/PayWithACH.vue'
import PointOfSaleEMV from '@/views/main/payment/components/PointOfSaleEMV.vue'
import store from '@/store'
import payment from '@/store/main/payment'
import { onUnmounted } from '@vue/composition-api'
import config from '@/views/main/payment/components/config'
import { isNull } from '@core/components/b-table-responsive/table-responsive/table/utils/inspect'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import ChargeSuccededModel from './components/modal/ChargeSuccededModel.vue'
import DisplayedErrorMessageFromBbposSystemModel from './components/modal/DisplayedErrorMessageFromBbposSystemModel.vue'
import InsertCreditCartonTerminalModel from './components/modal/InsertCreditCartonTerminalModel.vue'

export default {
  name: 'Payment',
  components: {
    BFormRadio,
    PointOfSaleEMV,
    PayWithACH,
    PayWithCreditCard,
    CreditCardOnFile,
    ChargeSuccededModel,
    DisplayedErrorMessageFromBbposSystemModel,
    InsertCreditCartonTerminalModel,
    BFormRadioGroup,
    BFormGroup,
    BForm,
    BRow,
    BCol,
  },
  props: {
    orderId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      selected: 'CreditCardOnFile',
      options: [
        { text: 'Credit Card on File', value: 'CreditCardOnFile' },
        { text: 'Pay with Credit Card', value: 'PayWithCreditCard' },
        { text: 'Pay with ACH', value: 'PayWithACH' },
        { text: 'Point of Sale (EMV)', value: 'PointOfSaleEmv' },
      ],
    }
  },
  computed: {
    paymentForm() {
      return this.$store.state[this.MODULE_NAME].paymentForm
    },
  },
  methods: {
    qwe() {
      this.$refs['charge-succeded-model'].show()
    },
    payment() {
      switch (this.selected) {
        case 'CreditCardOnFile':
          this.CreditCardOnFile()
          break
        case 'PayWithCreditCard':
          this.PayWithCreditCard()
          break
        case 'PayWithACH':
          this.PayWithACH()
          break
        case 'PointOfSaleEmv':
          this.PointOfSaleEmv()
          break
        default:
          console.log('Error type payment')
      }
    },
    CreditCardOnFile() {
      if (!isNull(this.$refs['credit-card-on-file'].selectedCard)) {
        const payload = {
          order_id: this.$route.params.id,
          payment_token: this.$refs['credit-card-on-file'].selectedCard,
          gateway_id: this.paymentForm.payment_gateway.id,
        }
        this.$store.dispatch(`${this.MODULE_NAME}/payWithToken`, payload).then(({ data: { data } }) => {
          window.location.reload()
        }).catch(err => {
          if (!isNull(err.message)) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: i18n.t('Selected order is not valid for payment', {
                  module: this.MODULE_NAME,
                }),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else {
            this.errorNotification(this, err)
          }
        })
      } else {
        // eslint-disable-next-line no-alert
        alert('please select a cart')
      }
    },
    PayWithCreditCard() {
      const payload = {
        xKey: 'ifields_tashkentdevdce484e2345e4c328978089629',
        xSoftwareName: 'EasyRam',
        xSoftwareVersion: '2.5.1905.0801',
        xVersion: '1.0',
      }
      const payloadCard = {
        ...payload,
        xTokenType: 'card',
        xData: this.paymentForm.cardNumber,
      }
      const payloadCVV = {
        ...payload,
        xTokenType: 'cvv',
        xData: this.paymentForm.cvv,
      }
      this.$store.dispatch(`${this.MODULE_NAME}/cardKnoxGetToken`, payloadCard).then(cardResponse => {
        this.$store.dispatch(`${this.MODULE_NAME}/cardKnoxGetToken`, payloadCVV).then(CVVResponse => {
          const payload = {
            order_id: this.$route?.params?.id,
            holder_name: this.paymentForm?.nameOnCard,
            card_number: cardResponse.data.xResultData.xToken,
            gateway_id: this.paymentForm?.payment_gateway.id,
            card_exp: this.paymentForm.exp_date.replace(/\//g, ''),
            cvv: CVVResponse.data.xResultData.xToken,
            zip_code: this.paymentForm.zip,
            save_card_info: this.paymentForm.keepCardInformationOnFile,

          }
          this.$store.dispatch(`${this.MODULE_NAME}/payWithCard`, payload).then(response => {
            window.location.reload()
          })
        })
      })
    },
    PayWithACH() {
      const payload = {
        xKey: 'ifields_tashkentdevdce484e2345e4c328978089629',
        xSoftwareName: 'EasyRam',
        xSoftwareVersion: '2.5.1905.0801',
        xVersion: '1.0',
        xTokenType: 'ach',
        xData: this.paymentForm.account_number,
      }
      this.$store.dispatch(`${this.MODULE_NAME}/cardKnoxGetToken`, payload).then(({ data }) => {
        const payWithAchData = {
          order_id: this.$route?.params?.id,
          account_number: data?.xResultData?.xToken ?? null,
          routing_number: this.paymentForm.routing_number,
          gateway_id: this.paymentForm?.payment_gateway?.id,
          save_account: true,
          account_type: this.paymentForm.account_type,
        }
        this.sendNotification(this, payWithAchData, `${this.MODULE_NAME}/payWithAch`)
          .then(() => {
            window.location.reload()
          }).catch(err => {
            this.errorNotification(this, err)
          })
      })
    },
    PointOfSaleEmv() {
      const payload = {
        endpoint: 'http://127.0.0.1:9001',
        data: {
          xCommand: 'cc:sale',
          xAmount: this.paymentForm.deposit + this.paymentForm.charge,
          xInvoice: this.$route.params.id,
          xZip: this.paymentForm.zip,
          xKey: 'tashkentdev9d5ec106b11a45508077a4c69967edd8',
        },
      }
      this.$refs['insert-credit-carton-terminal-model'].show()

      this.$store.dispatch(`${this.MODULE_NAME}/payViaTerminal`, payload)
        .then(({ data }) => {
          if (data.xResult === 'A') {
            this.$store.dispatch(`${this.MODULE_NAME}/approvedPayment`, { order_id: this.$route.params.id })
              .then(() => {
                window.location.reload()
              }).catch(err => {
                this.error = err.response?.data
              })
            this.$refs['insert-credit-carton-terminal-model'].hide()
            this.$refs['charge-succeded-model'].show()
          }

          if (data.xResult === 'E') {
            this.$refs['insert-credit-carton-terminal-model'].hide()
            this.$refs['displayed-error-message-from-bbpos-system-model'].content = data.xError
            this.$refs['displayed-error-message-from-bbpos-system-model'].show()
          }
        })
    },
    okSuccededModel() {
      this.$refs['charge-succeded-model'].hide()
    },
    cancelPayment() {
      const payload = {
        endpoint: 'http://127.0.0.1:9001',
        data: { xCancel: 1 },
      }

      this.$store.dispatch(`${this.MODULE_NAME}/cancelPayViaTerminal`, payload)
        .then(responce => {

        })
    },
    cancelErrorMessage() {
      this.$refs['displayed-error-message-from-bbpos-system-model'].hide()
    },
    tryAgainPayment() {
      this.$refs['displayed-error-message-from-bbpos-system-model'].hide()
      this.PointOfSaleEmv()
    },
    getProps(fieldName) {
      return {
        is: this.paymentGatewayFields[fieldName].type,
        field: this.paymentGatewayFields[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'payment'
    const LIS_MODULE_NAME = 'listModule'

    const { paymentGatewayFields } = config()

    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, payment)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })

    return {
      MODULE_NAME,
      LIS_MODULE_NAME,
      paymentGatewayFields,
    }
  },
}
</script>

<style scoped>
</style>
